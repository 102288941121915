.active-widget-panel{
    margin: 20px;
    padding: 10px;
    background-color: rgb(77, 77, 77);
    background: linear-gradient(to right, rgb(114, 114, 114), rgb(77, 77, 77));
    border-radius: 15px;
    box-sizing: border-box;
    text-align: center;
    width: calc(50% - 40px);
    max-width: 100%;
}

@media (max-width: 1200px) {
    .active-widget-panel{
        width: 100%;
    }
}

.active-widget-panel hr{
    margin: 0 0;
    opacity: 1;
    border: 1px solid rgb(255, 255, 255);
    width: 100%;
}

.active-widget-img-background{
    border-radius: 5px;
    margin: 20px;
    margin: 10px auto;
    padding: 10px;
    max-width: 128px;
    max-height: 128px;
}


.active-widget-panel h1{
    text-align: left;
    color: rgb(238, 238, 238);
    font-size: calc(10px + 1.5vmin);
}

.widget-url{
    text-align: left;
    width: 100%;
    height: 40px;
    margin: 10px;
    display: flex;
}

.widget-url p{
    color: rgb(238, 238, 238);
    font-size: calc(10px + 0.5vmin);
    white-space: nowrap;
    margin: auto;
    padding-right: 10px;
}

.widget-url-code{
    color: black;
    margin: 5px 0px;
    width: 100%;
}

.widget-url-reveal-icon{
    width: auto;
    margin: 0px 0px;
    padding: 0px 10px;
}

.actions{
    text-align: left;
}

.actions input{
    margin: 10px;
    max-width: 100px;
}