.widget-panel{
    margin: 20px;
    padding: 10px;
    background-color: rgb(146, 146, 146);
    border-radius: 15px;
    box-sizing: border-box;
    transition: background-color 0.1s ease;
    display: flex;
    width: calc(50% - 40px);
    max-width: 100%;
}

@media (max-width: 1000px) {
    .widget-panel{
        width: 100%;
    }
}

.widget-panel.selectable:hover{
    background-color: rgb(201, 201, 201);
    cursor: pointer;
}

.widget-img-background{
    background-color: rgb(77, 77, 77);
    border-radius: 5px;
    margin: 20px;
    padding: 10px;
    max-width: 128px;
    max-height: 128px;
}

.widget-info{
    width: 100%;
}

.widget-info h1{
    text-align: left;
    color: rgb(238, 238, 238);
    font-size: calc(10px + 1.5vmin);
}

.widget-info p{
    text-align: left;
    color: rgb(238, 238, 238);
    font-size: calc(10px + 0.5vmin);
}