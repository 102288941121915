p {
    text-align: center;
}

ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.footer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: 0;
    padding: 0 1rem;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.footer-link img {
    padding: 5px;
    max-width: 32px;
    max-height: 32px;
}
