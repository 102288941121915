.model-inputs label{
    padding: 10px;
}

.model-inputs input{
    width: 300px;
    height: 30px;
    max-width: 80%;
}

.model-inputs textarea{
    resize: none;
    width: 600px;
    height: 150px;
    max-width: 80%;
}

.model-inputs{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.model-outputs{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

/* Maintain the A4 ratio (height = width * 1.414) */
.output-text-box{
    resize: none;
    background-color: white;
    width: 70%;
    max-width: 1000px;
    min-height: 200px;
    height: 500px;
    margin: 20px auto;
    padding: 10px;
    border: 1px solid black;
    box-shadow: 0 0 2px black;
    box-sizing: border-box;
}