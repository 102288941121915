.model-inputs button{
    text-align: left;
    background-color: rgb(243, 243, 243);
    border: 1px solid black;
    box-shadow: 0 0 2px black;
    width: 200px;
    max-width: 80%;
    height: 30px;
    text-align: center;
    margin: 5px;
}

.model-inputs button:hover{
    cursor: pointer;
    background-color: rgb(209, 209, 209);
}

.model-inputs button:disabled{
    background-color: rgb(243, 243, 243);
    border: 1px solid rgb(104, 104, 104);
}

.model-inputs button:hover:disabled{
    cursor: default;
    background-color: rgb(243, 243, 243);
    border: 1px solid rgb(104, 104, 104);
}

.model-inputs input{
    width: 400px;
    height: 30px;
    max-width: 80%;
}

.model-inputs{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}