.form{
    background-color: white;
    width: 500px;
    max-width: 80%;
    margin: auto;
    box-shadow: 0 0 2px rgb(168, 168, 168);
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.register-input {
    padding: 20px;
    text-align: left;
    width: 80%;
    margin: auto;
}

.register-input input{
    display: block;
    width: 100%;
    height: 30px;
    margin: 0px;
    box-shadow: 2px 2px 2px rgb(168, 168, 168);
}

.register-submit input{
    background-color: rgb(97, 216, 97);
    color: white;
    font-size: large;
    width: 60%;
    height: 40px;
    border-radius: 25px;
    border: none;
    margin: 20px;
}

.register-submit input:hover{
    cursor: pointer;
    background-color: rgb(70, 180, 70);
}