
.account-form{
    width: 500px;
    max-width: 80%;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
}

.apikey-form{
    margin: auto;
    text-align: center;
    overflow-x: auto;
}

.apikey-table{
    border-collapse: collapse;
    margin: auto;
    text-align: left;
}

.apikey-table th{
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 8px;
}

.apikey-table td{
    border-bottom: 1px solid rgb(184, 184, 184);
    padding: 8px;
}

.apikey-table td input{
    width: 20%;
    height: auto;
    margin: 0px 0px;
    padding: 0px 10px;
}
