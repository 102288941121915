.model-inputs{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.model-inputs *{
    margin: 5px;
 }

.model-outputs{
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}