.widgets{
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    width: 100%;
}

.added-widgets{
    width: 100%;
    flex-wrap: wrap;
}

.added-widgets .widget-panel{
    display: flex;
    width: 100%;
    max-width: 100%;
}

.added-widgets{
    display: flex;
}
