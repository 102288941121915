.content{
  padding-top: 60px;
}

hr{
  opacity: 1;
  border: 1px solid black;
  width: 20%;
}

.button1{
  background-color: rgb(97, 216, 97);
  color: white;
  font-size: large;
  text-align: center;
  width: 60%;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin: 20px;
}

.button1:disabled{
  background-color: rgb(196, 252, 196);
  color: white;
  font-size: large;
  text-align: center;
  width: 60%;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin: 20px;
}

.button1:not(:disabled):hover{
  cursor: pointer;
  background-color: rgb(70, 180, 70);
}

.button2{
  background-color: rgb(216, 97, 97);
  color: white;
  font-size: large;
  text-align: center;
  width: 60%;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin: 20px;
}

.button2:hover{
  cursor: pointer;
  background-color: rgb(180, 70, 70);
}

.button3{
  background-color: rgb(216, 216, 216);
  color: black;
  font-size: large;
  text-align: center;
  width: 60%;
  height: 40px;
  border-radius: 25px;
  border: none;
  margin: 20px;
}

.button3:hover{
  cursor: pointer;
  background-color: rgb(165, 165, 165);
}

.button-s1{
  text-align: left;
  background-color: rgb(243, 243, 243);
  border: 1px solid black;
  box-shadow: 0 0 2px black;
  width: 60%;
  max-width: 100%;
  height: 30px;
  text-align: center;
}

.button-s1:hover{
  cursor: pointer;
  background-color: rgb(209, 209, 209);
}

body{
  min-height: calc(100vh - 200px);
}

h1{
  text-align: center;
}
