.chatbox{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 40%;
    max-width: 80%;
    height: 50vh;
    margin: 20px auto;
    padding: 10px;
    border: 1px solid black;
    box-shadow: 0 0 2px black;
    box-sizing: border-box;
    overflow: scroll;
    overflow-y: auto; 
}

@media (max-width: 1000px) {
    .chatbox{
        width: 80%;
    }
}

.message{
    font-size: large;
    word-wrap: break-word;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    min-width: 20%;
    max-width: 60%;
    margin: 5px;
    padding: 10px;
}

.left{
    background-color: rgb(230, 230, 230);
    border: 1px solid rgb(151, 151, 151);
    align-self: flex-start;
    left: 0px;
}

.right{
    background-color: rgb(182, 238, 255);
    border: 1px solid rgb(58, 119, 138);
    align-self: flex-end;
    right: 0px;
}