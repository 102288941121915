.nav-container {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 0 5px black;
    overflow: auto;
    overflow-y: hidden;
}

.navbar-left{
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.navbar-right{
    display: flex;
    margin-left: auto;
}

.nav-link {
    font-size: 1.5rem;
    margin: 0;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    color: black;
}

.nav-link:hover {
    text-decoration: underline;
}

.logo {
    width: 50px;
    padding: 0;
    height: auto;
    object-fit: contain;
    height: auto;
}