/*Project Banner Panel*/
.page-banner-panel {
    margin: 20px;
    border: 1px solid black;
    box-shadow: 0 0 5px black;
    border-radius: 5px;
    font-size: calc(10px + 1vmin);
    display: flex;
    width: 60%;
    max-width: 60%;
}

.page-banner-panel img {
    max-width: 100%;
    margin-right: 20px;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .page-banner-panel {
        margin: 20px;
        border: 1px solid black;
        box-shadow: 0 0 5px black;
        border-radius: 5px;
        font-size: calc(10px + 1vmin);
        display: flex;
        flex-direction: column;
        max-width: 60%;
    }
    .page-banner-panel img {
        max-width: 100%;
        margin-right: 0px;
    }
}

/*Project Banner Info*/
.page-banner-info{
    margin: 20px;
}

.page-banner-info h1{
    text-align: left;
}

.page-banner-info p{
    text-align: left;
}

/*
.page-banner-info button{
    text-align: left;
    background-color: rgb(243, 243, 243);
    border: 1px solid black;
    box-shadow: 0 0 2px black;
    max-width: 100%;
    height: 30px;
    text-align: center;
}

.page-banner-info button:hover{
    cursor: pointer;
    background-color: rgb(209, 209, 209);
}
*/
