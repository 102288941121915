.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(146, 146, 146, 0.2)
}

.modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    box-shadow: 0 0 5px black;
    margin: auto;
    min-height: 280px;
    min-width: 300px;
    width: 50vw;
    height: 50vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.modal h1{
    font-size: calc(10px + 1.5vmin);
}

.modal-body{
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.modal-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* optional label / input css */
.modal-body label {

}

.modal-input {
    padding: 20px;
    text-align: left;
    width: 80%;
    margin: auto;
}

.modal-input input{
    display: block;
    width: 100%;
    height: 30px;
    margin: 0px;
    box-shadow: 2px 2px 2px rgb(168, 168, 168);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.modal-input select{
    display: block;
    width: 100%;
    height: 30px;
    margin: 0px;
    box-shadow: 2px 2px 2px rgb(168, 168, 168);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
}
