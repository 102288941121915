.loading_screen {
    background-color: #d6ae7f;
    background-image: url('../../images/backgrounds/pexels-juan-pablo-serrano-arenas-1242348.jpg');
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-size: calc(10px + 2vmin);
}

.loading-icon {
    height: 40vmin;
    pointer-events: none;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}