.homepage header{
    background-color: #282c34;
    background-image: url('../../../images/backgrounds/pexels-juan-pablo-serrano-arenas-1242348.jpg');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.info-segment {
  margin: 20px;
  padding: 20px;
  border: 1px solid black;
  border-radius: 25px;
  font-size: calc(10px + 1vmin);
}

.info-segment h1 {
  text-align: center;
}

.info-segment p {
  text-align: left;
}

.table{
  display: flex;
  flex-wrap: wrap
}

.col {
  flex: 1;
  padding: 10px;
}

.col ul{
  display: block;
  list-style-type: disc;
}